.preview-canvas {
  -ms-interpolation-mode: bicubic;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  background-image:
    repeating-linear-gradient(90deg, #02a8, #02a8 1px, transparent 1px, transparent 10px),
    repeating-linear-gradient(0deg, #02a8, #02a8 1px, transparent 1px, transparent 10px);
}
